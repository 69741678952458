<template>
    <Transition>
        <section class="sideMenuContainer" id="sideMenu" v-if="visible">
            <div class="sideMenu">
                <div class="closeSideMenu" v-on:click="toggleMenu"><img src="../assets/x.png" alt=""></div>
                <li v-on:click="toggleMenu"><router-link to="/" class="menuElement">Inicio</router-link></li>
                <hr>
                <li v-on:click="toggleMenu"><router-link to="/articulos" class="menuElement">Artículos</router-link></li>
                <hr>
                <li v-on:click="toggleMenu"><router-link to="/entrevistas" class="menuElement">Entrevistas</router-link></li>
                <hr>
                <li v-on:click="toggleMenu"><router-link :to="{ path: '/', hash: '#about' }" class="menuElement">Sobre Mi</router-link></li>
            </div>
        </section>
    </Transition>
    <section class="navBar">    
        <div class="menuNavContainer">
            <router-link to="/"><img id="logoNav" src="../assets/logoNav_Light.png" alt="logoNav"></router-link>
            <div class="navMenu">
                <ul class="menu">
                    <li><router-link to="/" class="menuElement">Inicio</router-link></li>
                    <li><router-link to="/articulos" class="menuElement">Artículos</router-link></li>
                    <li><router-link to="/entrevistas" class="menuElement">Entrevistas</router-link></li>
                    <li><router-link :to="{ path: '/', hash: '#about' }" class="menuElement">Sobre Mi</router-link></li>
                </ul>
            </div>
            <div class="menuIcon" v-on:click="toggleMenu"></div>
        </div>
    </section>
</template>

<script>

export default {
    data(){
        return{
            visible: false
        }
    },
    methods: {
        toggleMenu(){
            this.visible = !this.visible;
        }
    }
}
</script>

<style>

</style>