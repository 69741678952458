<template>
    <div class="elementContainer featuredBlogsContainer">
        <div class="featuredBlogs">
            <h2>Blogs Recientes</h2>
            <div class="blogsContainer">
                <div class="blogItem">
                    <router-link to="articulos/tal-vez" class="blogCard">
                        <div class="blogImageContainer"><div class="blogImage" id="blog6Image"></div></div>
                        <div class="blogInfoContainer">
                            <h4>Tal Vez</h4>
                            <span>Tal vez te hayas despertado en una cama calentita, tal vez tu casa estaba calefaccionada. al vez te estiraste en la...</span>
                        </div>
                    </router-link>
                </div>
                <div class="blogItem">
                    <router-link to="articulos/apuntes-sobre-tria" class="blogCard">
                        <div class="blogImageContainer"><div class="blogImage" id="blog1Image"></div></div>
                        <div class="blogInfoContainer">
                            <h4>Apuntes Sobre Tría</h4>
                            <span>El triatlón es un deporte que consta de tres disciplinas que se realizan por etapas: natación, ciclismo y pedestrismo... </span>
                        </div>
                    </router-link>
                </div>
            </div>
            <router-link to="/articulos"><button class="button_style_1">Ver Más</button></router-link>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>