<template>
    <div class="footerContainer">
        <div class="elementContainer footer">
            <router-link to="/"><img id="logoNav" src="../assets/logoNav.png" alt="logoNav"></router-link>
            <p>Copyright © 2018 - 2024 PsicoDeporteando</p>
            <div class="socialIconsContainer">
                <a target="_blank" rel="noopener" href="https://wa.me/541159584020"><button class="socialIcon"><i class="fa-brands fa-whatsapp fa-xl"></i></button></a>
                <a target="_blank" rel="noopener" href="https://www.instagram.com/susanavella/"><button class="socialIcon"><i class="fa-brands fa-instagram fa-xl"></i></button></a>
                <a target="_blank" rel="noopener" href="https://www.youtube.com/@susanaavella5998"><button class="socialIcon"><i class="fa-brands fa-youtube fa-xl"></i></button></a>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>