<template>
    <header>
        <section class="banner" id="bannerA-Sol-As">
            <div class="bannerOverlay"></div>
            <div class="infoBanner">
                <h1 class="bannerHeading">Entreno, Siento... Escribo</h1>
                <h2 class="subHeadingBlog">Lic. Susana Avella - 28/12/2016</h2>
            </div>
        </section>
    </header>
    <main>
        <div style="background-color: #F3F3F3;" class="elementContainer">
            <div class="blogBodyContainer">
                <h2>Entreno, Siento... Escribo</h2>
                <div class="citaContainer" style="justify-content: flex-end;">
                    <p class="citaBlog" style="max-width: 600px; line-height: 2;">
                        <br>“Escribir era mi manera de golpear y abrazar. <br>
                        ¿Para qué sirve uno si no es para juntar sus pedazos?” <br> <br>
                        Eduardo Galeano <br> <br>
                    </p>
                </div>
                <p>
                    Estuve todo el año insistiéndote en que escribas, en <strong>TU</strong> “diario”.
                    Ahora que está finalizando el año, en estas épocas de “cierre y
                    balance” ¿qué voy a pedirte? Nuevamente, que escribas. No para ver
                    qué linda letra tenés o qué tan profundo redactás, sino que escribas
                    para <strong>VOS</strong>, para que te en encuentres con <strong>TU</strong> año, con tus mejores
                    momentos y logros del 2016. <br> <br>
                </p>
                <p>
                    <strong>¿Por qué y para qué es necesario escribir?</strong><br>
                    Vamos de vuelta, una vez más. <br> <br>
                    – Para que no ocupes tu cabeza con algo que puede estar en papel o en la compu. <br> <br>
                    – Porque al igual que pasa con tus hijos, a los que ves todos los días y pensás
                    que están igual y a lo largo del año crecieron varios centímetros, frecuentemente
                    en relación a tu deporte pensás que estás igual, que no avanzás, lo que muchas veces
                    te lleva a desmotivarte y a abandonar aquello que empezaste con tanto ímpetu. <br>
                    Cambiar de categoría, competir en varias, aumentar las distancias, cargar más peso,
                    pasar al profesionalismo, concurrir a un camp, ser convocado a un selectivo, incorporar
                    profesionales a tu equipo de trabajo (entrenador, preparador físico, kinesiólogo,
                    nutricionista, psicólogo, deportólogo, masajista, etc.) o espacios que te enriquecen
                    con nuevas técnicas y herramientas hacen a tus <strong>mejoras y logros</strong> como deportista. <br> <br>
                </p>
                <p>
                    – Para que veas tus pasos, tus logros, uno debajo de otro, muchos, pocos, grandes,
                    pequeños, significativos, gloriosos, increíbles, esforzados, merecidos, y ellos
                    cimenten tu <strong>autoconfianza</strong> y te lancen en la búsqueda de nuevos desafíos, acordes a tus
                    posibilidades y recursos. <br> <br>

                    – ¿Y si los logros hubiesen sido pocos? El verlos te ayudará para <strong>tu planteamiento de metas y objetivos</strong>
                    en el año entrante. Repensar y reflexionar por qué no pudiste alcanzar
                    lo que esperabas. ¿Hubo muchos distractores? ¿Te dispersaste en varios focos? ¿Eran objetivos
                    excesivos, ambiciosos? ¿Te lesionaste? ¿Tuviste problemas laborales/ personales o de otra índole?, etc. <br> <br>

                    – Para que dediques un tiempo a “recortar” tus mejores momentos del año, los plenos,
                    los de disfrute, los que evocan las mejores sensaciones, los que tenés que tener a mano
                    (en la <strong>“mochila o neceser psicológico”</strong>), cuando flaqueés, cuando quieras tirar la toalla,
                    esos que te van a “rescatar”, del abandono y de las excusas. <br> <br>

                </p>
                <p>
                    – Para saber también qué perdiste, qué soltaste y qué inauguraste gracias a eso.
                    ¿Qué carencia dejó a la vista? ¿Qué tuviste que salir a buscar? ¿Qué movilizó en vos? <br> <br>
                    
                    Te invito a que lo hagas, a que encuentres tu propio estilo, telegráfico, frondoso, escueto,
                    ampuloso, etc.  para expresar <strong>tus vivencias, tu recorrido</strong> de este año de trabajo que concluimos.
                </p>
            </div>
        </div>
    </main>
    <footer>
        <Footer/>
    </footer>
</template>

<script>
import Footer from '@/components/Footer.vue';


export default {
    components: {
        Footer,
    } 
}
</script>

<style>

</style>