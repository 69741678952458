<template>
    <section id="bannerHomepage">
        <div class="bannerHomepageOverlay"></div>
        <div class="infoBanner">
            <h1 class="bannerHeading">Lic. Susana Avella</h1>
            <h2 class="bannerSHeading">Psicóloga Clínica | Deportiva</h2>
        </div>
        <div class="container3D">
          <SplineLoader/>
        </div>
    </section>
</template>



<script>

import SplineLoader from '../components/SplineLoader.vue';


export default {
  components: {
    SplineLoader,
  }
}
</script>

<style>

</style>