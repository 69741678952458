<template>
    <header>
        <section class="banner" id="bannerA-Sol-As">
            <div class="bannerOverlay"></div>
            <div class="infoBanner">
                <h1 class="bannerHeading">Lo Ideal Y Lo Real No Siempre Coinciden</h1>
                <h2 class="subHeadingBlog">Lic. Susana Avella - 25/12/2019</h2>
            </div>
        </section>
    </header>
    <main>
        <div style="background-color: #F3F3F3;" class="elementContainer">
            <div class="blogBodyContainer">
                <h2>Lo Ideal Y Lo Real No Siempre Coinciden</h2>
                <a class="imageLinkContainer" href="https://vimeo.com/145198839" target="_blank">
                    <img class="imageLink" src="/assets/blogAssets/LoIdeal_thumb.jpg" alt="">
                </a>
                <p>
                    En el video Luis Alberto Hernando, ejemplo de atleta, tiene la virtud
                    de contarnos sobre un abandono. Inicia su “relato” contándonos que la
                    anterior versión de Mont Blanc cometió el error de “pensar”, de pensar
                    en las horas que faltaban. Saber controlar nuestro pensamiento en un
                    deporte individual como es el trail running, es fundamental.
                    El autodiálogo en algunas circunstancias puede presentarse imparable
                    y vertiginosamente precipitarse hacia lo negativo como un alud.
                    Es óptimo tener/conocer algunas técnicas para detener esa cadena de
                    pensamientos (parada de pensamiento) y sustituirla por otra que sea
                    más eficiente (autoafirmaciones, auto refuerzos, autoinstrucciones). <br> <br>
                    Luego nos habla de la importancia del EQUIPO. Todos los deportistas,
                    aunque no sean profesionales “tienen un equipo” y cuando uno enfrenta
                    un desafío como el Cruce está bueno saber quién es quién. El Entrenador,
                    con sus indicaciones técnicas, tácticas, estratégicas. Nuestros compañeros
                    y deportistas significativos, referentes que han pasado con anterioridad
                    por esa circunstancia y nos transmiten su vasta y valiosa experiencia.
                    El médico deportólogo con la suplementación, la hidratación, la organización
                    de los alimentos y el descanso. El psicólogo deportivo con técnicas de
                    respiración y relajación para sobrellevar momentos de ansiedad y estrés
                    y optimizar el descanso. También con la implementación de técnicas de
                    refocalización para recuperar la concentración. El kinesiólogo,
                    el masajista, el nutricionista, etc. <br><br>
                </p>
                <p>
                    También nos habla de los objetivos de entrenamiento (“foto chula,
                    disfrutar, hacer cima”) versus los objetivos de competencia
                    (“competencia pura y dura, ganar a los rivales”). Finalmente nos
                    habla del cambio de meta en su última incursión a la UTMB.
                    Tras el abandono que tuvo producto del “pensar” se propone entrar
                    en la posición que sea, saber si es capaz de correr 100 millas,
                    se propone correr, caminar rápido, caminar lento pero avanzar…
                    siempre avanzar. Nos alerta que a más millas más problemas y éste
                    es el punto donde quiero poner hoy el foco. En el caso del Cruce
                    no sólo son los km sino también los días, el traslado de las pertenencias,
                    las actividades propias del campamento y la convivencia lo que puede
                    traer aparejados problemas. Todos tienen en sus cabezas la carrera
                    ideal, el Cruce Ideal, que no es el REAL. No siempre lo IDEAL y lo REAL coinciden. <br> <br>

                    No les quiero pinchar el globo, todo lo contrario. Les sugiero que
                    tengan en cuenta los imponderables que pueda surgir, y piensen cómo
                    los podrían sortear, cuáles son sus recursos ante esas eventualidades.
                    Los invito a que desde la comodidad de sus casas dispensen
                    tiempo y energía en visualizar esos posibles acontecimientos e
                    imaginen soluciones, distintas opciones y las ensayen mentalmente.
                    Les va a salir más “barato” que tener que improvisar en la montaña.
                    La visualización es una técnica dúctil ya que podemos hacerla donde
                    sea y cuantas veces queramos. Estar anticipado es tener un plan para
                    poner en marcha si suceden eventos desfavorables y volver a estar en
                    carrera en el menor tiempo posible y con el menor gasto energético.
                    Estas rutinas de refocalización pueden constar de: las acciones a
                    realizar, en qué nos vamos a concentrar, qué frase gatillo nos vamos a
                    decir, qué música podemos escuchar, etc. Estas rutinas previamente
                    pensadas, imaginadas y practicadas mentalmente mediante la visualización
                    también forman parte del Bolso psicológico (Ver posteo 04/01/2016). <br> <br>

                    Ustedes pueden aprovechar una bajada porque entrenan cuestas,
                    pueden “explotar” al ver el arco de llegada porque hacen pasadas en
                    los entrenos, si quieren sacarle provecho a lo mental, “practiquen”
                    en sus casas las escenas temidas, que son distintas para cada quien,
                    por su personalidad, por la trayectoria en la disciplina, por las
                    experiencias vividas, etc. Para finalizar, junto con la visualización
                    es deseable que entrenen “simulando”, es decir que los entrenamientos
                    progresivamente sean variados y lo más parecido en exigencia, clima,
                    presión, estrés, vestimenta, acumulación de cansancio, etc. al evento
                    en el que vamos a participar. Tan duro y tan real como sea posible.
                    (Entre los Navy Seals se dice “train as you fight”). No se fíen en
                    que lo exigente va a salir sino lo practican “exigente”. <br><br>
                </p>
                <div class="citaContainer" style="justify-content: center;">
                    <p class="citaBlog" style="text-align: center; max-width: 600px; line-height: 2;">
                        “ No hago las cosas creyendo a medias. <br>
                        Sé que al hacerlo así sólo puedo esperar resultados mediocres. <br>
                        Por eso me concentro en los entrenamientos tanto como en los juegos. <br>
                        Es una actitud que se puede abrir y cerrar como si fuera un grifo. <br>
                        Sería imposible hacerme el tonto en los entrenamientos y después, <br>
                        al necesitar más empuje en el final de un juego, <br>
                        pretender que el esfuerzo se refleje.” <br> <br>
                        Michael Jordan <br>
                        “Mi filosofía del triunfo”
                    </p>
                </div>
            </div>
        </div>
    </main>
    <footer>
        <Footer/>
    </footer>
</template>

<script>
import Footer from '@/components/Footer.vue';


export default {
    components: {
        Footer,
    } 
}
</script>

<style>

</style>