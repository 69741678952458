<template>
    <header>
        <section class="banner" id="bannerArticulos">
            <div class="bannerOverlay"></div>
            <div class="infoBanner">
                <h1 class="bannerHeading">Artículos</h1>
            </div>
        </section>
    </header>
    <main>
        <div class="elementContainer">
            <div class="blogsContainer">
                <div class="blogItem">
                    <router-link to="articulos/tal-vez" class="blogCard">
                        <div class="blogImageContainer"><div class="blogImage" id="blog6Image"></div></div>
                        <div class="blogInfoContainer">
                            <h4>Tal Vez</h4>
                            <span>Tal vez te hayas despertado en una cama calentita, tal vez tu casa estaba calefaccionada. al vez te estiraste en la...</span>
                        </div>
                    </router-link>
                </div>
                <div class="blogItem">
                    <router-link to="articulos/apuntes-sobre-tria" class="blogCard">
                        <div class="blogImageContainer"><div class="blogImage" id="blog1Image"></div></div>
                        <div class="blogInfoContainer">
                            <h4>Apuntes Sobre Tría</h4>
                            <span>El triatlón es un deporte que consta de tres disciplinas que se realizan por etapas: natación, ciclismo y pedestrismo...</span>
                        </div>
                    </router-link>
                </div>
                <div class="blogItem">
                    <router-link to="articulos/el-habito-te-hace-monje" class="blogCard">
                        <div class="blogImageContainer"><div class="blogImage" id="blog2Image"></div></div>
                        <div class="blogInfoContainer">
                            <h4>El Hábito Te Hace Monje</h4>
                            <span>El objetivo principal de la rutina es generar familiaridad y confianza en el atleta, que recurra y encuentre las sensaciones...</span>
                        </div>
                    </router-link>
                </div>
                <div class="blogItem">
                    <router-link to="articulos/a-sol-as" class="blogCard">
                        <div class="blogImageContainer"><div class="blogImage" id="blog3Image"></div></div>
                        <div class="blogInfoContainer">
                            <h4>A-Sol-As</h4>
                            <span>En estos días de cuarentena y pandemia escuchamos, leemos acerca de la importancia de estar al sol para que se active...</span>
                        </div>
                    </router-link>
                </div>
                <div class="blogItem">
                    <router-link to="articulos/lo-ideal-y-lo-real" class="blogCard">
                        <div class="blogImageContainer"><div class="blogImage" id="blog4Image"></div></div>
                        <div class="blogInfoContainer">
                            <h4>Lo Ideal y lo Real no Siempre Coinciden</h4>
                            <span>En el video Luis Alberto Hernando, ejemplo de atleta, tiene la virtud de contarnos sobre un abandono...</span>
                        </div>
                    </router-link>
                </div>
                <div class="blogItem">
                    <router-link to="articulos/entreno-siento-escribo" class="blogCard">
                        <div class="blogImageContainer"><div class="blogImage" id="blog5Image"></div></div>
                        <div class="blogInfoContainer">
                            <h4>Entreno, Siento... Escribo</h4>
                            <span>“Escribir era mi manera de golpear y abrazar. ¿Para qué sirve uno si no es para juntar sus pedazos?"...</span>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </main>
    <footer>
        <Footer/>
    </footer>
</template>

<script>
import Footer from '@/components/Footer.vue';

export default {
  components: {
    Footer,
  }
}
</script>

<style scoped>

.footerContainer{
    background-color: #F1F1F1;
}

</style>