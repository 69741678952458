<template>
    <header>
        <SideMenu/>
        <section class="banner" id="bannerArticulos">
            <div class="bannerOverlay"></div>
            <div class="infoBanner">
                <h1 class="bannerHeading">Entrevistas</h1>
            </div>
        </section>
    </header>
    <main>
        <div style="background-color: #F3F3F3;" class="elementContainer">
            <div class="entrevistasContainer">
                <h2 style="margin-bottom: 0px;">Rediseñarse</h2>
                <h3 style="font-weight: 400;">con Sebastián Guerrini</h3>
                <p style="text-align: center;">Domingo 27 de septiembre 2020 <br><br></p>
                <iframe width="900" height="500" src="https://www.youtube.com/embed/tpHdTKmst0c?si=EF_6CMTZVeflrAHG" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
        </div>
    </main>
    <footer>
        <Footer/>
    </footer>

</template>

<script>
import Footer from '@/components/Footer.vue';

export default {
  
  components: {
    Footer,
  }
}
</script>

<style>

</style>