<template>
    <div class="elementContainer">
        <div class="aboutMe" id="about">
            <img class="aboutImage" src="../assets/susanaAvella.jpg" alt="susanaAvella">
            <div class="aboutInfo">
                <h2 class="aboutHeading">Lic. Susana Avella</h2>
                <p class="aboutPara">Soy Susana Avella, psicóloga con especialización en
                    Psicología Deportiva y Clínica, graduada de la Universidad
                    de Buenos Aires (UBA). Mi pasión es ayudar a las personas a
                    alcanzar su máximo potencial, ya sea en el ámbito deportivo o
                    en su bienestar general.
                </p>
                <a target="_blank" rel="noopener" href="https://wa.me/541159584020"><button class="button_style_1">Contacto</button></a>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>