<template>
  <spline-viewer class="splineContainer" url="https://prod.spline.design/kdQMMnpWqo1XzKm5/scene.splinecode"></spline-viewer>
</template>
  
<script>
  export default {
    mounted() {
      this.loadScript();
    },
    methods: {
      loadScript() {
        const script = document.createElement('script');
        script.type = 'module';
        script.src = 'https://unpkg.com/@splinetool/viewer@0.9.489/build/spline-viewer.js';
        script.onload = this.onScriptLoaded;
        document.body.appendChild(script);
      },
      onScriptLoaded() {
        var splineViewer = document.querySelector('spline-viewer');
        if(splineViewer){
            var shadowRoot = splineViewer.shadowRoot;
            var logo = shadowRoot.querySelector('#logo');
            if (logo){
                logo.remove();
            }
        }
      },
    },
  };
</script>
  
<style scoped>
  /* Add your component-specific styles here */
</style>
  